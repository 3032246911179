.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 10px auto;
}

input {
  padding: 0.5em 1.2em;
  border-radius: 25px;
  font-size: medium;
  border: none;
  outline-color: #07d196;
}

.picture-box {
  margin: 1em;
  max-width: 300px;
}

.link-desc {
  overflow-x: scroll;
  color: #333;
  padding: 0.9em;
  white-space: nowrap;
  font-size: small;
  border-radius: 0 0 10px 10px;
}

.search-bar {
  display: flex;
  position: sticky;
  top: 0px;
  background: linear-gradient(45deg, #00a8d6e7 30%, #00a542ad 70%);
  padding: 1em 0 0 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 20%);
  align-content: center;
  justify-content: space-around;
  align-items: flex-start;
}

.logo img {
  width: 150px;
}

@media screen and (max-width: 550px) {
  .search-bar {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .logo img {
    width: 120px;
    padding: 0.5em 0;
  }
}

.c_loudinary-button {
  border-radius: 50%;
  background: linear-gradient(45deg, rgb(82, 255, 29), #00d0ff);
  width: 70px;
  font-weight: 400;
  height: 70px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  font-size: 35px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 2px 9px rgb(0 0 0 / 50%);
}
