@font-face {
  font-family: unineue;
  src: url('./fonts/UniNeueRegular.otf') format('opentype');
}

body {
  margin: 0;
  font-family: unineue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
